import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import './scss/styles.scss';
import { HeroBannerImage } from 'components/heroBanner';
import { Layout } from 'layout';

const ContactAuthor = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <Layout
      darkHeader
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.contactPromoTitle
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
    >
      <HeroBannerImage
        backgroundImage={
          markdownRemark.frontmatter.contactPromoBackground &&
          markdownRemark.frontmatter.contactPromoBackground.children[0].fixed
            .src
        }
        title={markdownRemark.frontmatter.form.title}
        description={markdownRemark.frontmatter.form.description}
        heroImage={
          markdownRemark.frontmatter.contactPromoImage.image &&
          markdownRemark.frontmatter.contactPromoImage.image.children[0].fluid
        }
        heroImageText={markdownRemark.frontmatter.contactPromoImage.text}
        noGradient
        displayContactsForm
      />
    </Layout>
  );
};

ContactAuthor.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const contactAuthorQuery = graphql`
  query ContactAuthor($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      id
      tableOfContents(maxDepth: 2)
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        authorSection {
          title
          description
          linkUrl
          linkText
          image {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        companySection {
          title
          description
          linkUrl
          linkText
          image {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        contactPromoImage {
          text
          image {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 335, maxHeight: 387) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        contactPromoBackground {
          children {
            ... on ImageSharp {
              fixed(width: 1500) {
                src
              }
            }
          }
        }
        contactPromoDescription
        contactPromoTitle
        form {
          title
          description
        }
        templateKey
      }
    }
  }
`;

export default ContactAuthor;
